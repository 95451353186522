export function routes($stateProvider) {

  $stateProvider.state('app.gestaoContratual', {
    url: '/gestaoContratual',
    template: '<gestao-contratual></gestao-contratual>'
  })

}

routes.$inject = ['$stateProvider'];
