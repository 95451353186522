export default class TariffService {
  constructor($q, Tariff, Site, Tariffcycle, Provider, AuthenticationService) {
    this.$q = $q;
    this.Tariff = Tariff;
    this.Site = Site;
    this.Tariffcycle = Tariffcycle;
    this.Provider = Provider;
    this.Auth = AuthenticationService;
  }

  /*
   * Get list of tariffs based on entity of user
   */
  list = (filter) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();

    if (!filter.where) {
      filter = {
        where: {
          entityId: user.site.entityId
        }
      }
    } else {
      filter.where.entityId = user.site.entityId;
    }
    filter.include = [
      "tariffcycle",
      "provider"
    ];

    this.Tariff.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Tariff.find({
          filter: filter
        }).$promise.then((r) => {
          return defer.resolve({
            total: c.count,
            data: r,
          });
        })
          .catch((e) => defer.reject(e));
      })
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  /*
   * Get list of tariffs based on entity of user
   */
  get = (id) => {
    let defer = this.$q.defer();

    this.Tariff.findOne({
      filter: {
        where: {
          id: id,
        },
        include: [
          "tariffcycle",
          "provider",
          "evses"
        ],
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));

    return defer.promise;
  };

  // Generator template for energy cycles
  tableGenerator = () => {
    let defer = this.$q.defer();
    let table = [];
    // Days of week
    // This is moment's ISO Day of Week (1 - Monday, 7 - Sunday
    for (let dayWeek = 1; dayWeek < 8; dayWeek++) {
      // Every 15 min
      let o = {
        dayOfWeek: dayWeek,
        timetable: [],
      };
      for (let i = 0; i < 24 * 4; i++) {
        o.timetable.push({
          i: i,
        });
      }
      table.push(o);
    }
    defer.resolve(table);
    return defer.promise;
  };

  /*
   * Return list of chargers grouped by site
   */
  chargers = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        where: {
          active: true,
          entityId: {
            inq: user.entityIds,
          },
        },
        include: "evses",
      },
    })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  cycles = () => {
    let defer = this.$q.defer();
    this.Tariffcycle.find({})
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  providers = () => {
    let defer = this.$q.defer();
    this.Provider.find({})
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  save = (data) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    data.entityId = user.site.entityId;
    this.Tariff.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  delete = (id) => {
    let defer = this.$q.defer();
    this.Tariff.deleteById({ id: id })
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

TariffService.$inject = [
  "$q",
  "Tariff",
  "Site",
  "Tariffcycle",
  "Provider",
  "AuthenticationService"
];
