export default class EvseProfileListController {
  constructor($state, EvseProfileService, UIService) {
    this.$state = $state;
    this.EvProfile = EvseProfileService;
    this.UI = UIService;
    this.loaded = false;

    this.filter = {
      page: $state.params.page || 1,
      sort: "asc",
      order: "vendor",
    };

    this.loadData();
  }

  readNumberOfPhases = (row) => {
    row.numberOfPhases = row.numberOfPhases || 1;
    return row.numberOfPhases == 1 ? "Monofásico" : "Trifásico";
  }

  loadData = () => {
    this.EvProfile.list(this.createFilter()).then(r => {
      this.total = r.total;
      this.start = r.total > 0 ? (this.filter.page - 1) * 50 + 1 : 0;
      this.end = r.total > 0 ? (this.filter.page - 1) * 50 + r.data.length : 0;
      this.data = r.data;
      this.loaded = true;
    }).catch(e => {
      this.UI.addToast("Ocorreu um erro ao carregar perfis de carregador");
    });
  }

  createFilter = () => {

    return {
      where: {},
      limit: 50,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 50,
    };
  };

  next = () => {
    if (this.end < this.total) {
      this.filter.page++;
    }
    this.loadData();
  };

  previous = () => {
    if (this.filter.page > 1) {
      this.filter.page--;
    }
    this.loadData();
  };

  clear = () => {
    this.filter.page = this.$state.params.page || 1;
    this.filter.sort = "asc";
    this.filter.order = "vendor";
    this.loadData();
  };

  add = () => {
    this.UI.showDialog({
      controller: 'EvseProfileDialogController',
      controllerAs: 'd',
      template: require('./dialog/view.html'),
      size: 'lg',
      resolve: {
        data: () => undefined
      }
    })
  }

  edit = (row) => {
    this.UI.showDialog({
      controller: 'EvseProfileDialogController',
      controllerAs: 'd',
      template: require('./dialog/view.html'),
      resolve: {
        data: () => angular.copy(row)
      }
    }).then(r => {
      this.EvProfile.save(r).then(_ => {
        this.UI.addToast("Perfil atualizado!");
        this.loadData();
      })
    });
  }

  add = () => {
    this.UI.showDialog({
      controller: 'EvseProfileDialogController',
      controllerAs: 'd',
      template: require('./dialog/view.html'),
      resolve: {
        data: () => undefined
      }
    }).then(r => {
      this.EvProfile.save(r).then(_ => {
        this.UI.addToast("Perfil criado!");
        this.loadData();
      })
    });
  }
}

EvseProfileListController.$inject = ['$state', 'EvseProfileService', 'UIService'];
