import {
  routes
} from './routes';
import EvseService from './service';
import EVSEListController from './list/controller';
import EVSEDetailsController from './details/controller';
import EVSEMapController from './map/controller';

export default angular.module('app.evse', [])
  .config(routes)
  .service('EvseService', EvseService)
  .controller('EVSEListController', EVSEListController)
  .controller('EVSEDetailsController', EVSEDetailsController)
  .controller('EVSEMapController', EVSEMapController)
  .filter('fileSize', function () {

    let fileSize = function (size, unit, fixedDigit) {
      return size.toFixed(fixedDigit) + ' ' + unit;
    };

    return function (size) {
      if (isNaN(size))
        size = 0;

      if (size < 1024)
        return size + ' bytes';

      size /= 1024;

      if (size < 1024)
        return fileSize(size, 'KB', 2);

      size /= 1024;

      if (size < 1024)
        return fileSize(size, 'MB', 2);

      size /= 1024;

      if (size < 1024)
        return fileSize(size, 'GB', 2);

      size /= 1024;
      return fileSize(size, 'TB', 2);
    };
  })
  .name;

