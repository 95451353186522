export function routes($stateProvider) {

  $stateProvider.state('app.tarifario', {
    url: '/tarifario',
    abstract: true,
    template: '<ui-view></ui-view>'
  }).state("app.tarifario.list", {
    url: "/lista",
    template: "<tarifario-lista></tarifario-lista>",
  }).state("app.tarifario.venda", {
    url: "/venda",
    template: "<tarifario-venda></tarifario-venda>",
  })

}

routes.$inject = ['$stateProvider'];
