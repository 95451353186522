export default class LayoutController {
  constructor(
    $scope,
    $rootScope,
    AuthenticationService,
    AuthorizationService,
    $state,
    UIService,
    NotificationService,
    User,
    Access,
    Entity,
    $location
  ) {
    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.NotificationService = NotificationService;
    this.$state = $state;
    this.UI = UIService;
    this.UserModel = User;
    this.Access = Access;
    this.location = $location;
    this.Entity = Entity;
    this.entities = [];
    this.entityFilterName = "";
    this.filter = {
      term: "",
      limit: 5,
    };
    this.t = AuthenticationService.getToken();

    $scope.$on("$receivedServerStatus", (evt, data) => {
      this.status = data;
      if (!data.environment.includes("production")) {
        this.env = "Ambiente de desenvolvimento";
      }
      $rootScope.status = data;
    });

    this.menuItems = [];

    this.user = AuthenticationService.getUser();
    this.loadEntities();

    this.isAuthenticated = () => {
      AuthenticationService.isAuthenticated();
    };

    this.loadNotifications();
    NotificationService.listener((c) => {
      this.loadNotifications();
    });
    this.parseMenu();
  }

  hasSiteChoice = () => {
    if (!this.entities) return false;
    let count = 0;
    this.entities.forEach((e) => {
      count += e.sites.length;
    });
    return count > 1;
  };

  showSites = (entity, event) => {
    this.entities.forEach((r) => {
      if (entity.id == r.id) {
        r.selected = true;
      } else {
        r.selected = false;
      }
    });
    //Prevent close on click
    event.stopPropagation();
  };

  loadEntities = () => {
    if (this.user.level == 999) {
      this.Entity.find({
        filter: {
          where: {
            active: true,
          },
          order: "name",
          include: {
            relation: "sites",
            scope: {
              where: {
                active: true,
              },
              include: "entity",
            },
          },
        },
      }).$promise.then((entities) => {
        entities = entities.filter(
          (r) => r.active == true && r.sites.length > 0
        );
        entities.forEach((r) => {
          let a = r.sites.filter((s) => s.id == this.user.siteId);
          if (a.length > 0) r.selected = true;
        });
        this.entities = entities;

        this.parseMenu();
      });
    } else {
      this.UserModel.findById({
        id: this.user.id,
        filter: {
          include: {
            relation: "tenants",
            scope: {
              include: "entity",
            },
          },
        },
      }).$promise.then((user) => {
        this.Access.find({
          filter: {
            where: {
              userId: this.user.id,
            },
            include: { relation: "site", scope: { include: "entity" } },
          },
        }).$promise.then((access) => {
          if (access.length > 0 && this.user.siteId == undefined) {
            this.AuthenticationService.updateSite(access[0].site);
            this.user.site = access[0].site;
            this.user.siteId = access[0].siteId;
          }
          let entities = [];
          user.tenants.forEach((r) => {
            if (r.entity) {
              r.entity.sites = [];
              entities.push(r.entity);
            }
          });
          access.forEach((r) => {
            entities.forEach((e) => {
              if (r.site.entityId == e.id) {
                e.sites.push(r.site);
              }
            });
          });
          entities = entities.filter((r) => r.sites.length > 0);
          this.entities = entities;
          this.parseMenu();
        });
      });
    }
  };

  //increase limit of data to fetch from database
  loadMore = () => {
    this.filter.limit += 5;
    this.loadEntities();
  };

  clearDropdown = () => {
    this.filter.term = "";
    this.filter.limit = 5;
    this.loadEntities();
  };

  createFilter = () => {
    let ob = {};
    let prop = "";
    let pattern = "";
    let where = {};

    if (!this.filter.term.isEmpty()) {
      // Filter by name
      prop = `name`;
      pattern = {
        like: `.*${this.filter.term}.*`,
        options: "i",
      };
      ob = {};
      ob[prop] = pattern;
      where = ob;
    }
    return {
      where: where,
    };
  };

  loadNotifications = () => {
    this.NotificationService.load().then((r) => {
      this.notifications = r;
    });
  };

  filteredEntities = () => {
    if (!this.entityFilterName.trim().length) {
      return this.entities;
    }
    let data = this.entities.filter((r) =>
      r.name.toUpperCase().includes(this.entityFilterName.toUpperCase())
    );
    data.sort((a, b) => a.name.localeCompare(b.name));
    data.forEach((r, i) => {
      r.selected = i == 0;
    });
    return data;
  };

  setEntity = (entity) => {
    this.AuthenticationService.updateEntity(entity);
  };

  setSite = (site) => {
    this.AuthenticationService.updateSite(site);
  };

  siteFilter = (a, b) => {
    return true;
  };

  updateNotification = (notification) => {
    notification.seen = true;
    notification.$save();
    this.loadNotifications();
  };

  logout = () => {
    this.AuthenticationService.logout();
  };

  parseMenu = () => {
    const jsonMenu = require("../navigation.json");
    // Getting list of all states, so that role will be loaded from states...
    let states = this.$state.get();
    states = _.filter(states, (s) => {
      // Ignore abstract routes and placeholders
      return s.hasOwnProperty("template") && (s.hasOwnProperty("controller") || s.hasOwnProperty("react"));
    });

    // Iterate the sidebar options through the states, to get the required role for it
    let menu = [];
    jsonMenu.forEach((section) => {
      if (section.hasOwnProperty("opt")) {
        let u = this.AuthenticationService.getUser();
        if (section.opt == "site") {
          section.name = u.site.name;
        }
        if (section.opt == "entity") {
          section.name = u.site.entity.name;
        }
      }
      if (section.hasOwnProperty("sections")) {
        let k = [];
        section.sections.forEach((o) => {
          let s = _.find(states, {
            name: o.state,
          });
          if (angular.isDefined(s)) {
            if (s.hasOwnProperty("role")) {
              if (this.AuthorizationService.canPerform(s.role)) k.push(o);
            } else k.push(o);
          } else {
            // Do nothing... :)
          }
        });
        if (k.length > 0) {
          section.sections = k;
          menu.push(section);
        }
      } else {
        let s = _.find(states, {
          name: section.state,
        });
        if (angular.isDefined(s)) {
          if (s.hasOwnProperty("role")) {
            if (this.AuthorizationService.canPerform(s.role)) {
              menu.push(section);
            }
          } else {
            menu.push(section);
          }
        } else {
          // Do nothing... :)
        }
      }
    });
    this.menuItems = menu;
  };
}

LayoutController.$inject = [
  "$scope",
  "$rootScope",
  "AuthenticationService",
  "AuthorizationService",
  "$state",
  "UIService",
  "NotificationService",
  "User",
  "Access",
  "Entity",
];
