import React, { useState } from "react";
import { Box, Button, Card, Container, Grid, TextField, Typography, } from "@mui/material";
export default function TarifarioVenda() {
    const [editable, setEditable] = useState(false);
    const [margemHGP, setMargemHGP] = useState();
    const [margemEntidade, setMargemEntidade] = useState();
    const [valorCar, setValorCar] = useState();
    const [valorMin, setValorMin] = useState();
    const [valorKw, setValorKw] = useState();
    const [valorOcup, setValorOcup] = useState();
    const handleEdit = () => {
        setEditable(!editable);
    };
    return (<Container maxWidth="lg">
      <h1>Tarifário de Venda</h1>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {/* Group 1 */}
          {false && (<Grid item xs={12} sx={{ pb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={2}>
                  <Typography>Margem HGP </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={10}>
                  <TextField value={margemHGP} type="number" variant="outlined" InputProps={{
                style: {
                    height: "30px",
                },
                readOnly: !editable,
            }} onChange={(e) => setMargemHGP(+e.target.value)}/>
                </Grid>
              </Grid>
            </Grid>)}

          {/* Group 2 */}
          {false && (<Grid item xs={12} sx={{ pb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={2}>
                  <Typography>Margem Entidade </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={10}>
                  <TextField value={margemEntidade} type="number" variant="outlined" InputProps={{
                style: {
                    height: "30px",
                },
                readOnly: !editable,
            }} onChange={(e) => setMargemEntidade(+e.target.value)}/>
                </Grid>
              </Grid>
            </Grid>)}

          {/* Group 3 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/carga </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorCar} type="number" variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => setValorCar(+e.target.value)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 4 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/min </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorMin} type="number" variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => setValorMin(+e.target.value)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 5 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/kWh </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorKw} type="number" variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => setValorKw(+e.target.value)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 6 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/ocupação </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorOcup} type="number" variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => setValorOcup(+e.target.value)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Edit Button */}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="caption">
                  <b>Nota:</b> Taxa de ocupação é o valor a debitar ao
                  utilizador quando o carregamento terminou mas continua a
                  ocupar o espaço do carregador, impossibilitando outros de
                  carregar
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
        <Button variant="contained" onClick={handleEdit}>
          {editable ? "Salvar" : "Editar"}
        </Button>
      </Box>
    </Container>);
}
