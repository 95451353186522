export function routes($stateProvider) {

  $stateProvider.state('app.locations', {
    url: '/locations',
    abstract: true,
    template: '<ui-view></ui-view>'
  }).state("app.locations.list", {
    url: "/lista",
    template: "<locations-list></locations-list>",
  }).state("app.locations.charger", {
    url: "/carregadores/:country_code/:party_id/:id",
    template: "<charger-list></charger-list>",
  }).state("app.locations.session", {
    url: "/sessoes/:uid",
    template: "<session-list></session-list>",
  })

}

routes.$inject = ['$stateProvider'];
