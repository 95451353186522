export default class EvseProfileService {
  constructor($q, EvseProfile) {
    this.$q = $q;
    this.EvseProfile = EvseProfile;
  }

  list = (filter) => {
    let defer = this.$q.defer();
    this.EvseProfile.count({
      where: filter.where,
    }).$promise.then((r) => {
      this.EvseProfile.find({
        filter: filter,
      }).$promise.then((list) => {
        defer.resolve({
          total: r.count,
          data: list,
        });
      }).catch((err) => {
        defer.reject(err);
      });
    }).catch((err) => {
      defer.reject(err);
    });
    return defer.promise;
  };

  save = data => {
    if (data.id != undefined) {
      return this.EvseProfile.upsert(data).$promise;
    }
    return this.EvseProfile.create(data).$promise;
  }
}

EvseProfileService.$inject = ['$q', 'EvseProfile']
