export default class AdminUserService {
  constructor($q, User, Access, Entity, Tenant) {
    this.$q = $q;
    this.User = User;
    this.Access = Access;
    this.Entity = Entity;
    this.Tenant = Tenant;
  }

  list = query => {
    let defer = this.$q.defer();
    this.User.count(query.filter).$promise.then(c => {
      this.User.find(query).$promise.then(r => {
        defer.resolve({
          total: c.count,
          data: r,
        });
      }).catch((err) => defer.reject(err));
    }).catch((err) => defer.reject(err));

    return defer.promise;
  }

  create = (data) => {
    let defer = this.$q.defer();
    // Populate data
    data.user.blocked = false;
    data.user.createdAt = new Date();
    data.level = 1;
    this.User.create(data.user).$promise
      .then((result) => {
        let tenants = data.tenants.map(r => r.id);
        this.registerTenants(result.id, tenants).then(r => {
          this.registerAccess(result.id, data.access).then(r => {
            defer.resolve(result);
          }).catch((err) => {
            defer.reject(err);
          });
        }).catch((err) => {
          defer.reject(err);
        });
        defer.resolve(result);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  };

  registerTenants = (userId, array) => {
    let defer = this.$q.defer();
    let promises = [];
    array.forEach(r => {
      promises.push(this.createTenant(userId, r));
    });
    this.$q.all(promises).then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  createTenant = (userId, entityId) => {
    let defer = this.$q.defer();
    this.Tenant.find({
      filter: {
        where: {
          userId: userId,
          entityId: entityId
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        defer.resolve(r[0]);
      } else {
        this.Tenant.create({
          userId: userId,
          entityId: entityId
        }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
      }
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  registerAccess = (userId, array) => {
    let defer = this.$q.defer();
    let promises = [];
    array.forEach(r => {
      promises.push(this.createAccess(userId, r));
    });
    this.$q.all(promises).then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  createAccess = (userId, siteId) => {
    let defer = this.$q.defer();
    this.Access.find({
      filter: {
        where: {
          userId: userId,
          siteId: siteId
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        defer.resolve(r[0]);
      } else {
        this.Access.create({
          userId: userId,
          siteId: siteId,
          expires: false
        }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
      }
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  update = (user) => {
    let defer = this.$q.defer();
    this.User.upsert(user).$promise.then((result) => defer.resolve(result)).catch((err) => defer.reject(err));
    return defer.promise;
  };

  delete = id => {
    let defer = this.$q.defer();
    this.User.deleteById({
      id: id
    }).$promise.then((users) => defer.resolve(users)).catch(e => defer.reject(e));
    return defer.promise;
  }

  pickTenantSites = (tenants, isNewUser) => {
    let defer = this.$q.defer();
    if (isNewUser) {
      this.Entity.find({
        filter: {
          where: {
            id: {
              inq: tenants
            }
          },
          include: {
            relation: 'sites',
            scope: {
              where: {
                active: true
              }
            }
          }
        }
      }).$promise.then(r => {
        defer.resolve(r);
      });
    } else {

    }
    return defer.promise;
  }

  identifierExists = (identifier) => {
    let defer = this.$q.defer();
    this.Users.count({
      where: {
        cards: {
          elemMatch: identifier
        }
      }
    }).$promise.then(r => {
      defer.resolve(r.count == 0);
    }).catch(e => {
      defer.reject();
    });
    return defer.promise;
  }
}

AdminUserService.$inject = ['$q', 'User', 'Access', 'Entity', 'Tenant'];
