export function routes($stateProvider) {

  $stateProvider
    .state('paywall.checkout', {
      url: '/checkout?{c:string}&{t:string}',
      template: require('./checkout/view.html'),
      controller: 'CheckoutController',
      controllerAs: 'vm',
    })
    .state('paywall.confirm', {
      url: '/confirm?i',
      template: require('./confirm/view.html'),
      controller: 'ConfirmController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
