export function routes($stateProvider) {
  $stateProvider
    .state('app.reports', {
      abstract: true,
      url: '/reports',
      template: '<ui-view></ui-view>'
    })
    .state('app.reports.entity', {
      url: '/entity',
      template: require('./entity/view.html'),
      controller: 'ReportEntityController',
      controllerAs: 'vm',
      role: ['editSite']
    })
    .state('app.reports.site', {
      url: '/site',
      template: require('./site/view.html'),
      controller: 'ReportSiteController',
      controllerAs: 'vm',
      role: ['editSite']
    })
    .state('app.reports.user', {
      url: '/user',
      template: require('./user/view.html'),
      controller: 'ReportUserController',
      controllerAs: 'vm',
      role: ['controlPanel']
    });
}

routes.$inject = ['$stateProvider'];
