import React from "react";
import { Card, Container, Grid, Typography } from "@mui/material";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
function Sessoes({ $state }) {
    const { uid } = $state.params;
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid md={6} sx={{ mt: 3, ml: 1.8 }}>
          <Typography variant="h6" sx={{ color: "black" }}>
            Localização {/*uid*/}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 6 } }}>
          <Card sx={{ p: 6, height: "auto" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  UID
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Status
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Data Ultima Atualizacao
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Tomadas
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold", mb: 0.5 }}>
                  Capabilities
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  ...
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  ...
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  ...
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  ...
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "black", mb: 0.5 }}>
                  ...
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 6 } }}>
          <Card sx={{ p: 6, height: "62.5%" }}>
            <Grid container>
              <Grid xs={4}>Imagens</Grid>
              <Grid xs={4}>Texto</Grid>
              <Grid xs={4}>Texto</Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}></Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", mb: 1 }}>
            Histórico de Carregamentos (Sessions) - Últimos 7 dias
          </Typography>
          
        </Grid>
      </Grid>
    </Container>);
}
export default Sessoes;
