import { routes } from './routes';
import gestaoContratual from './gestaoContratual/gestaoContratual.tsx';
import GestaoContratualService from './services.ts';
import { react2angular } from 'react2angular';

export default angular.module('app.gestaoContratual', [])
    .config(routes)
    .service('GestaoContratualService', GestaoContratualService)
    .component("gestaoContratual", react2angular(gestaoContratual, [], ['GestaoContratualService','UIService']))
    .name;
