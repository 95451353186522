import React, { useEffect, useState } from "react";
import { Card, Typography, Stack, Box, MenuItem, IconButton, Tooltip, Modal, Button, Snackbar, } from "@mui/material";
import Icon from "@mdi/react";
import { mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import { useMemo } from "react";
import { MaterialReactTable, useMaterialReactTable, } from "material-react-table";
import "../utils/utils.css";
const Contracts = ({ $state, AdministrationService }) => {
    const [contractList, setContractsLiast] = useState([]);
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [toDelete, setToDelete] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        // Load -> Contracts / User / Entities
        const fetchProjects = async () => {
            try {
                const resource = await AdministrationService.getUserContracts($state.params.id);
                const userInfo = await AdministrationService.getUser($state.params.id);
                setUser(userInfo[0]);
                const entities = await AdministrationService.getEntities();
                const entityMap = entities.reduce((acc, entity) => {
                    acc[entity.id] = entity.name;
                    return acc;
                }, {}); // entityMap ->  { idEntidade: "Nome", ...}
                // Change date format in contracts
                const modifiedEntities = resource.map((contract) => ({
                    ...contract,
                    dataInicio: contract.dataInicio.slice(0, 10),
                    dataFim: contract.dataFim.slice(0, 10),
                    entityId: entityMap[contract.entityId], // Change id entity to name antity
                }));
                setContractsLiast(modifiedEntities);
                setLoading(false);
            }
            catch (error) {
                if (contractList.length === 0) {
                    console.error("Erro ao obter contratos:", error.message);
                    setSnackMessage("Erro ao obter contratos");
                    setOpenSnackbar(true);
                }
                if (user === undefined) {
                    console.error("Erro ao obter user:", error.message);
                    setSnackMessage("Erro ao obter utilizador");
                    setOpenSnackbar(true);
                }
            }
        };
        if (loading) {
            fetchProjects();
        }
    }, []);
    const SimpleModal = (props) => {
        const { open, onClose, message, id } = props;
        const handleClose = (result) => {
            onClose(result, id);
        };
        return (<Modal open={open} onClose={() => handleClose(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="simpleModal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {message}
          </Typography>
          <Stack direction="row" spacing={2} position="absolute" bottom="10px" right="10px">
            <Button sx={{ color: "black" }} onClick={() => handleClose(false)}>
              Cancelar
            </Button>
            <Button sx={{ color: "red" }} onClick={() => handleClose(true)}>
              Ok
            </Button>
          </Stack>
        </Box>
      </Modal>);
    };
    const CostumTable = ({ data }) => {
        //should be memoized or stable
        const columns = useMemo(() => [
            {
                accessorKey: "entityId",
                header: "Entidade",
                size: 150,
            },
            {
                accessorKey: "rescisao",
                header: "Ativo",
                size: 50,
                Cell: ({ cell }) => `${cell.getValue() ? "Não" : "Sim"}`,
            },
            {
                accessorKey: "dataInicio",
                header: "Data Inicio",
                size: 80,
            },
            {
                accessorKey: "dataFim",
                header: "Data Fim",
                size: 80,
            },
            {
                accessorKey: "diaFaturacao",
                header: "Dia Faturação",
                size: 50,
            },
            {
                accessorKey: "valorMensal",
                header: "Valor Mensal",
                size: 100,
                Cell: ({ cell }) => `${cell.getValue()} €`,
            },
            {
                accessorKey: "valorCharge",
                header: "Valor de Carga",
                size: 100,
                Cell: ({ cell }) => `${cell.getValue()} €`,
            },
            {
                accessorKey: "valorMin",
                header: "Valor por Minuto",
                size: 100,
                Cell: ({ cell }) => `${cell.getValue()} €`,
            },
            {
                accessorKey: "valorOcupacao",
                header: "Valor de ocupação /Minuto",
                size: 100,
                Cell: ({ cell }) => `${cell.getValue()} €`,
            },
            {
                accessorKey: "valorKwh",
                header: "Valor Kwh",
                size: 100,
                Cell: ({ cell }) => `${cell.getValue()} €`,
            },
        ], []);
        const table = useMaterialReactTable({
            columns,
            data,
            enableFullScreenToggle: false, // remove fullscreen button
            enableRowActions: true, // Action Row
            positionActionsColumn: "last", // Action Row last position
            displayColumnDefOptions: {
                "mrt-row-actions": {
                    header: "Ações", //change header text
                    size: 80, //make actions column wider
                },
            },
            renderRowActionMenuItems: ({ row }) => [
                <Tooltip title="Editar Contrato" key="edit" placement="left">
          <MenuItem onClick={() => $state.go("app.administration.users.edit-contractEdit", {
                        id: user.id,
                        contractId: row.original.id,
                    })} key="edit">
            <Icon path={mdiPencil} size={1}/>
          </MenuItem>
        </Tooltip>,
                <Tooltip title="Eliminar Contrato" key="delete" placement="left">
          <MenuItem onClick={() => deleteContract(row.original.id)} key="delete">
            <Icon path={mdiDelete} size={1}/>
          </MenuItem>
        </Tooltip>,
            ],
        });
        return <MaterialReactTable table={table}/>;
    };
    const deleteContract = (id) => {
        setToDelete(id);
        handleModal("Pretende eliminar o contrato?");
    };
    const handleModal = (message) => {
        setModalOpen(true);
        setModalMessage(message);
    };
    const handleModalClose = async (result) => {
        setModalOpen(false);
        if (result) {
            try {
                await AdministrationService.deleteContract(toDelete);
                setContractsLiast((prevContracts) => prevContracts.filter((contract) => contract.id !== toDelete));
                setSnackMessage("Contrato Eliminado");
                setOpenSnackbar(true);
            }
            catch (error) {
                console.error("Erro ao eliminar contrato:", error);
                setSnackMessage("Erro ao eliminar contrato");
                setOpenSnackbar(true);
            }
        }
        else {
            setToDelete("");
        }
    };
    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    if (loading) {
        return <Box></Box>;
    }
    return (<Card sx={{ padding: "10px" }}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Typography variant="h6">Contratos</Typography>
          <Tooltip title="Adicionar Contrato">
            <IconButton aria-label="add" onClick={() => $state.go("app.administration.users.edit-contract", {
            id: user.id,
            siteId: user.id
            //siteId: user.siteId === undefined ? user.id : user.siteId,
        })}>
              <Icon path={mdiPlus} size={1}/>
            </IconButton>
          </Tooltip>
        </Stack>

        {loading ? <Box></Box> : <CostumTable data={contractList}/>}
      </Stack>

      <SimpleModal open={modalOpen} onClose={handleModalClose} message={modalMessage}/>
      <Snackbar open={openSnackbar} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} autoHideDuration={2500} onClose={closeSnackbar} message={snackMessage}/>
    </Card>);
};
export default Contracts;
