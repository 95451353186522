import { routes } from "./routes";
import TariffService from "./service";
import TariffListController from "./list/controller";
import TariffWizardController from "./wizard/controller";
import TariffDetailsController from "./details/controller";
import { react2angular } from "react2angular";
import TarifarioVenda from "./sell/view.tsx";
import TarifarioLista from "./sell/list.tsx";

export default angular
  .module("app.tariff", [])
  .config(routes)
  .component("tariffSell", react2angular(TarifarioVenda, [], []))
  .component("tariffList", react2angular(TarifarioLista, [], ['$state']))
  .controller("TariffListController", TariffListController)
  .controller("TariffWizardController", TariffWizardController)
  .controller("TariffDetailsController", TariffDetailsController)
  .service("TariffService", TariffService).name;
