export function routes($stateProvider) {
  $stateProvider.state('app.tariffcycle', {
    url: '/tariffcycle',
    abstract: true,
    template: '<ui-view></ui-view>'
  }).state('app.tariffcycle.list', {
    url: '?{page:int}&{term:string}&{order:string}&{sort:string}',
    template: require('./list/view.html'),
    controller: 'TariffcycleListController',
    controllerAs: 'vm',
    role: ['controlPanel']
  }).state('app.tariffcycle.details', {
    url: '/?{id}',
    template: require('./details/view.html'),
    controller: 'TariffcycleDetailsController',
    controllerAs: 'vm',
    role: ['controlPanel']
  }).state('app.tariffcycle.new', {
    url: '/new',
    template: require('./new/view.html'),
    controller: 'TariffcycleNewController',
    controllerAs: 'vm',
    role: ['controlPanel']
  });
}

routes.$inject = ['$stateProvider'];
