import {
  routes
} from './routes';
import TransactionService from './service';
import TransactionListController from './list/controller';
import TransactionDetailsController from './details/controller';

export default angular.module('app.transaction', [])
  .config(routes)
  .service('TransactionService', TransactionService)
  .controller('TransactionListController', TransactionListController)
  .controller('TransactionDetailsController', TransactionDetailsController)
  .name;
