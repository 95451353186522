import React from "react";
import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Container } from "@mui/material";
import axios from "axios";
import moment from "moment";
function OCPICDRList() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    async function GetUsers() {
        let response;
        try {
            response = await axios.get("https://dev-hgp-ocpi.streamline.pt/hgp/cdrs/direct/", {
                headers: {
                    Authorization: "Token 2d83dd7c-ad5e-475f-ad7f-c4b51ce06d57",
                },
            });
        }
        catch (error) {
            throw error;
        }
        return response.data;
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const data = await GetUsers();
            setData(data);
            setIsLoading(false);
        };
        fetchData();
    }, []);
    function formatTotalTime(totalTime) {
        const totalHours = parseFloat(totalTime);
        const hours = Math.floor(totalHours);
        const minutes = Math.round((totalHours - hours) * 60);
        return `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`;
    }
    const columns = useMemo(() => [
        {
            accessorKey: "cdr_location.id",
            header: "ID Localização",
            size: 50,
        },
        {
            accessorKey: "cdr_token.contract_id",
            header: "ID Contrato",
            size: 50,
        },
        {
            accessorKey: "start_date_time",
            header: "Data de Início do Carregamento",
            size: 50,
            Cell: ({ row }) => moment(row.original.start_date_time).format("DD/MM/YYYY HH:mm"),
        },
        {
            accessorKey: "end_date_time",
            header: "Data de Fim do Carregamento",
            size: 50,
            Cell: ({ row }) => moment(row.original.end_date_time).format("DD/MM/YYYY HH:mm"),
        },
        {
            accessorKey: "total_cost.excl_vat",
            header: "Custo Total Sem IVA (€)",
            size: 50,
            Cell: ({ row }) => (<div style={{ textAlign: "right" }}>
            {parseFloat(row.original.total_cost.excl_vat).toFixed(2)} €
          </div>),
        },
        {
            accessorKey: "total_time",
            header: "Tempo de Carregamento (hh:mm)",
            size: 100,
            Cell: ({ row }) => (<div style={{ textAlign: "right" }}>
            {formatTotalTime(row.original.total_time)}
          </div>),
        },
        {
            accessorKey: "total_energy",
            header: "Energia Consumida (kWh)",
            size: 50,
            Cell: ({ row }) => (<div style={{ textAlign: "right" }}>{row.original.total_energy}</div>),
        },
    ], []);
    return (<Container maxWidth={false}>
      <h1>Informação de carregamentos</h1>
      <h3>Caminho</h3>
      <MaterialReactTable columns={columns} data={data} state={{ density: "compact", isLoading: isLoading }} muiCircularProgressProps={{
            color: "secondary",
            thickness: 5,
            size: 55,
        }}/>
    </Container>);
}
export default OCPICDRList;
