export function routes($stateProvider) {
  $stateProvider
    .state('app.evse', {
      url: '/evse',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.evse.list', {
      url: '?{page:int}&{order:string}&{sort:string}&{term:string}&{filterSerie:string}&{charging:bool}&{offline:bool}',
      template: require('./list/view.html'),
      controller: 'EVSEListController',
      controllerAs: 'vm'
    })
    .state('app.evse.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'EVSEDetailsController',
      controllerAs: 'vm'
    }).state('app.evse.map', {
      url: '/map',
      template: require('./map/view.html'),
      controller: 'EVSEMapController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
