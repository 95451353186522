import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Container, Grid, MenuItem, Paper, Select, Switch, TextField, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const initialValues = {
    userId: "",
    entityId: "",
    dataInicio: undefined,
    dataFim: undefined,
    rescisao: undefined,
    valorMensal: 0,
    valorCharge: 0,
    valorMin: 0,
    diaFaturacao: undefined,
};
function gestaoContratual({ GestaoContratualService, UIService }) {
    const [users, setUsers] = useState([]);
    const [entities, setEntities] = useState([]);
    const [entidade, setEntidade] = useState("6");
    const [value, setValue] = useState("1");
    const [utilizador, setUtilizador] = useState("-1");
    const [isGroup6Visible, setIsGroup6Visible] = useState(false);
    const [isGroup11Visible, setIsGroup11Visible] = useState(false);
    const [isRescindChecked, setIsRescindChecked] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Make the API call using axios
                const usersData = await GestaoContratualService.findAllUsers();
                const entitiesData = await GestaoContratualService.findAllEntitys();
                const mappedUsers = usersData.map((user) => ({
                    id: user.id,
                    name: user.name,
                }));
                const mappedEntities = entitiesData.map((entity) => ({
                    id: entity.id,
                    name: entity.name,
                }));
                setEntities(mappedEntities);
                setUsers(mappedUsers);
            }
            catch (error) {
                console.error("Error fetching users:", error);
                console.error("Error fetching entities:", error);
            }
        };
        fetchData();
    }, []);
    const handleCheckboxChange11 = (event) => {
        setIsGroup11Visible(event.target.checked);
    };
    const handleRescindChange = (e) => {
        setIsRescindChecked(e.target.checked);
        setIsGroup6Visible(e.target.checked);
    };
    const requiredSchema = yup.object({
        utilizador: yup.string().required("*Por favor insira um utilizador válido"),
        instalacao: yup
            .string()
            .required("*Por favor insira um tipo de instalação válida"),
        dataInicio: yup
            .date()
            .required("*Por favor insira uma data inicial válida"),
        dataFim: yup.date().required("*Por favor insira uma data final válida"),
        valorMensal: yup
            .number()
            .required("*Por favor insira um valor mensal válido"),
        valorCharge: yup
            .number()
            .required("*Por favor insira um valor de carga válido"),
        valorMin: yup
            .number()
            .required("*Por favor insira um valor por minuto válido"),
        diaFaturacao: yup
            .date()
            .required("*Por favor insira uma data de faturação válida"),
    });
    const notRequiredSchema = yup.object({
        utilizador: yup.string(),
        instalacao: yup.string(),
        dataInicio: yup.date(),
        dataFim: yup.date(),
        valorMensal: yup.number(),
        valorCharge: yup.number(),
        valorMin: yup.number(),
        diaFaturacao: yup.date(),
    });
    const validationSchema = yup
        .object({
        utilizador: yup.string(),
        instalacao: yup.string(),
        dataInicio: yup.date(),
        dataFim: yup.date(),
        valorMensal: yup.number(),
        valorCharge: yup.number(),
        valorMin: yup.number(),
        diaFaturacao: yup.date(),
        dataRescisao: yup.date().notRequired(),
    })
        .test("conditional-validation", "*", function (value) {
        const isRescindChecked = this.parent?.utilizador === "6"; // Adjust this condition according to your requirement
        const schemaToValidate = isRescindChecked
            ? requiredSchema
            : notRequiredSchema;
        return schemaToValidate
            .validate(value, { abortEarly: false })
            .then(() => true)
            .catch((err) => {
            // You can handle the validation error here if needed
            throw err;
        });
    });
    const onSubmit = async (values) => {
        try {
            let formValues = {};
            if (!isRescindChecked) {
                if (!values.diaFaturacao) {
                    values.diaFaturacao = values.dataInicio;
                }
                formValues = {
                    userId: utilizador,
                    entityId: entidade,
                    dataInicio: values.dataInicio,
                    dataFim: values.dataFim,
                    rescisao: undefined,
                    valorMensal: values.valorMensal,
                    valorCharge: values.valorCharge,
                    valorMin: values.valorMin,
                    diaFaturacao: values.diaFaturacao,
                };
            }
            else {
                formValues = {
                    rescisao: true,
                };
            }
            await GestaoContratualService.createContract(formValues);
            UIService.addToast("Dados submetidos com sucesso");
        }
        catch (error) {
            UIService.addToast("Erro na submição de dados");
            // Handle validation errors
            //console.error("Validation Error:", error);
        }
    };
    const formik = useFormik({
        validateOnMount: true,
        validateOnBlur: false,
        initialValues,
        validationSchema,
        onSubmit,
    });
    const handleUtilizadorChange = (event) => {
        const selectedId = event.target.value;
        const selectedOption = users.find((option) => option.id === selectedId);
        setUtilizador(selectedOption ? selectedOption.id : "-1");
        formik.setFieldValue("utilizador", selectedOption ? selectedOption.id : "");
    };
    const handleEntidadeChange = (event) => {
        const selectedId = event.target.value;
        const selectedOption = entities.find((option) => option.id === selectedId);
        setEntidade(selectedOption ? selectedOption.id : "");
        formik.setFieldValue("entidade", selectedOption ? selectedOption.id : "");
    };
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
      <Container maxWidth={false}>
        <h1>Gestão Contratual</h1>
        <h5>Caminho</h5>
        <form onSubmit={formik.handleSubmit}>
          <TabContext value={value}>
            <Paper style={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={(_e, newValue) => setValue(newValue)} aria-label="lab API tabs example">
                  <Tab label="Detalhes" value="1"/>
                  <Tab label="Financeiro" value="2"/>
                  <Tab label="Faturação" value="3"/>
                </TabList>
              </Box>

              <TabPanel value="1">
                <Grid container spacing={2}>
                  {/* Group 1 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography sx={{ pt: 0.3 }}>Utilizador </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <Select id="utilizador" name="utilizador" value={utilizador} onChange={handleUtilizadorChange} style={{ height: "30px" }} disabled={isRescindChecked}>
                          <MenuItem value="-1" disabled>
                            Escolha um Utilizador
                          </MenuItem>
                          {users.map((user) => (<MenuItem key={user.id} value={user.id}>
                              {user.name}
                            </MenuItem>))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 2 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Entidade </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <Select id="entidade" name="entidade" value={entidade} onChange={handleEntidadeChange} style={{ height: "30px" }} disabled={isRescindChecked}>
                          <MenuItem value="6" disabled>
                            Escolha uma Entidade
                          </MenuItem>
                          {entities.map((entity) => (<MenuItem key={entity.id} value={entity.id}>
                              {entity.name}
                            </MenuItem>))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 3 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Data de Inicio </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("dataInicio", value)} defaultValue={formik.values.dataInicio} slotProps={{
            textField: {
                variant: "outlined",
                id: "dataInicio",
                InputProps: {
                    style: {
                        height: "30px",
                    },
                },
                disabled: isRescindChecked,
                //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
            },
        }}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 4 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Data de Fim </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("dataFim", value)} defaultValue={formik.values.dataFim} slotProps={{
            textField: {
                variant: "outlined",
                id: "dataFim",
                InputProps: {
                    style: {
                        height: "30px",
                    },
                },
                disabled: isRescindChecked,
                //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
            },
        }}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 5 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2} sx={{ mt: 0.3 }}>
                        <Typography>Rescindir Contrato? </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10} sx={{ mt: -0.8 }}>
                        <Switch checked={isRescindChecked} onChange={handleRescindChange}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 6 (DatePicker) */}
                  {isGroup6Visible && (<Grid item xs={12} sx={{ pb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          <Typography>Data de Rescição </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={10}>
                          <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("dataRescisao", value, true)} defaultValue={formik.values.rescisao} slotProps={{
                textField: {
                    variant: "outlined",
                    id: "dataRescisao",
                    InputProps: {
                        style: {
                            height: "30px",
                        },
                    },
                    //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                    //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
                },
            }}/>
                        </Grid>
                      </Grid>
                    </Grid>)}

                  {/* Avançar Button */}
                  <Grid item xs={12}>
                    <Grid item>
                      <Typography variant="caption">
                        <b>Nota:</b> Ao rescindir contrato impossibilta o
                        utilizador de carregar na instalação. Será depois
                        obrigatória a criação de um novo contrato
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value="2">
                <Grid container spacing={2}>
                  {/* Group 7 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Valor €/Mensal</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <TextField type="number" id="valorMensal" name="valorMensal" variant="outlined" value={formik.values.valorMensal} onChange={formik.handleChange} InputProps={{
            style: {
                height: "30px",
            },
            inputProps: {
                step: "3", // This allows decimal values
            },
        }} disabled={isRescindChecked}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 8 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Valor €/Carregamento </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <TextField type="number" id="valorCharge" name="valorCharge" variant="outlined" value={formik.values.valorCharge} onChange={formik.handleChange} InputProps={{
            style: {
                height: "30px",
            },
            inputProps: {
                step: "3", // This allows decimal values
            },
        }} disabled={isRescindChecked}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 9 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Valor €/min </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <TextField type="number" id="valorMin" name="valorMin" variant="outlined" value={formik.values.valorMin} onChange={formik.handleChange} InputProps={{
            style: {
                height: "30px",
            },
            inputProps: {
                step: "3", // This allows decimal values
            },
        }} disabled={isRescindChecked}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 5 */}
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={2}>
                        <Typography>Definir dia de faturação? </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={10}>
                        <Switch disabled={isRescindChecked} checked={isGroup11Visible} onChange={handleCheckboxChange11}/>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Group 11 */}
                  {isGroup11Visible && (<Grid item xs={12} sx={{ pb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          <Typography>Dia de Faturação </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={10}>
                          <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("diaFaturacao", value)} defaultValue={formik.values.diaFaturacao} slotProps={{
                textField: {
                    variant: "outlined",
                    id: "diaFaturacao",
                    InputProps: {
                        style: {
                            height: "30px",
                        },
                    },
                    //error: formik.touched.diaFaturacao && Boolean(formik.errors.diaFaturacao),
                    //helperText: formik.touched.diaFaturacao && formik.errors.diaFaturacao
                },
            }}/>
                        </Grid>
                      </Grid>
                    </Grid>)}

                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <b>Nota:</b> Caso não seja definido a data de faturação,
                      será utilizado o dia da data de início do contrato.
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value="3">
                <Grid container spacing={2}>
                  
                  <Grid item xs={12} sx={{ pb: 1 }}>

                  </Grid>
                </Grid>
              </TabPanel>

            </Paper>
          </TabContext>

          <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
            <Button type="submit" variant="contained">
              Submeter
            </Button>
          </Box>
        </form>
      </Container>
    </LocalizationProvider>);
}
export default gestaoContratual;
