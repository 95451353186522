export default class GestaoContratualService {
    contract;
    user;
    entity;
    constructor(contract, user, entity) {
        this.contract = contract;
        this.user = user;
        this.entity = entity;
    }
    findAllContracts() {
        return this.contract.find().$promise;
    }
    findAllUsers() {
        return this.user.find().$promise;
    }
    findAllEntitys() {
        return this.entity.find().$promise;
    }
    patchContract(id, data) {
        return this.contract.patch({ id }, data).$promise;
    }
    createContract(data) {
        return this.contract.create(data).$promise;
    }
}
;
GestaoContratualService.$inject = ['Contract', 'User', 'Entity'];
