export function routes($stateProvider) {
  $stateProvider
    .state("app.ocpi", {
      abstract: true,
      url: "/ocpi",
      template: "<ui-view></ui-view>",
    })
    .state("app.ocpi.locations-list", {
      url: "/locations",
      template: "<list-locations></list-locations>",
      react: true,
    })
    .state("app.reports.locations-view", {
      url: "/locations/:country_code/:party_id/:id",
      template: "<charger-list></charger-list>",
    })
    .state("app.reports.locations-sessions", {
      url: "/locations/:uid",
      template: "<session-list></session-list>",
    })
    .state("app.ocpi.cdrs-list", {
      url: "/cdrs",
      template: "<list-cdrs></list-cdrs>",
      react: true,
    });
}

routes.$inject = ["$stateProvider"];
