export default class GroupDetailsController {
  constructor($state, $http, UIService, GroupService) {
    this.$state = $state;
    this.$http = $http;
    this.UI = UIService;
    this.Groups = GroupService;
    this.id = $state.params.id;

    this.loaded = false;

    if (this.id) {
      this.label = "Editar";
      this.loadData();
    } else {
      this.data = {
        active: true,
      };
      this.label = "Adicionar";
      this.loaded = true;
    }
  }

  loadData = () => {
    this.Groups.get(this.id)
      .then((r) => {
        this.loaded = true;
        this.data = r;
      })
      .catch((e) => {
        this.loaded = true;
        this.UI.addToast("Ocorreu um erro ao carregar grupos de carregadores");
      });
  };

  save = () => {
    if (this.validate()) {
      this.Groups.save(this.data)
        .then((r) => {
          if (this.id) {
            this.UI.addToast("Grupo alterado com sucesso");
          } else {
            this.id = r.id;
            this.UI.addToast("Grupo adicionado com sucesso");
          }
          this.$state.go("app.group.details", {
            id: this.id,
          });
        })
        .catch((e) => {
          this.UI.addToast("Não foi possível gravar grupo");
        });
    }
  };

  validate = () => {
    if (!this.data.name) {
      this.UI.addToast("Insira o nome do grupo de carregadores");
      return false;
    }
    if (!this.data.power) {
      this.UI.addToast("Insira a potência máxima");
      return false;
    }
    return true;
  };
}

GroupDetailsController.$inject = [
  "$state",
  "$http",
  "UIService",
  "GroupService",
];
