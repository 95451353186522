export function routes($stateProvider) {
  $stateProvider
    .state("app.tariff", {
      url: "/tariff",
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.tariff.list", {
      url: "?{page:int}&{term:string}&{order:string}&{sort:string}",
      template: require("./list/view.html"),
      controller: "TariffListController",
      controllerAs: "vm",
    })
    .state("app.tariff.wizard", {
      url: "/wizard",
      template: require("./wizard/view.html"),
      controller: "TariffWizardController",
      controllerAs: "vm",
    })
    .state("app.tariff.sell-list", {
      url: "/sell",
      template: require("./sell/list.html"),
      controller: "DummyController",
      controllerAs: "vm"
    })
    .state("app.tariff.sell-details", {
      url: "/sell/:id",
      template: require("./sell/view.html"),
      controller: "DummyController",
      controllerAs: "vm"
    })
    .state("app.tariff.details", {
      url: "/:id",
      template: require("./details/view.html"),
      controller: "TariffDetailsController",
      controllerAs: "vm",
      role: ["editTariff"],
    });
}

routes.$inject = ["$stateProvider"];
