export default class DashboardAdminController {
  constructor(Metrics, $interval) {
    this.Metrics = Metrics;
    this.period = 30;
    this.loaded = {
      energy: false,
      revenue: false,
      user: false,
      typeChart: false,
      powerChart: false,
      statusChart: false,
      issues: false,
      active: false,
      sessions: false
    }
    this.metrics = {};
    this.loadData();
    $interval(() => {
      this.loadData();
    }, 2 * 1000 * 60);
  }

  loadData = () => {
    this.adminIssues();
    this.adminCharging();
    this.adminSessions();
    this.adminEnergy();
    this.adminRevenue();
    this.adminUsers();
    this.getTypeChart();
    this.getStatusChart();
    this.getVendorChart();
  }

  adminIssues = () => {
    this.loaded.issues = false;
    this.Metrics.adminIssues().$promise.then(r => {
      this.metrics.issues = r;
      this.loaded.issues = true;
    });
  }

  adminCharging = () => {
    this.loaded.active = false;
    this.Metrics.adminCharging().$promise.then(r => {
      this.metrics.active = r;
      this.loaded.active = true;
    });
  }

  adminSessions = () => {
    this.loaded.sessions = false;
    this.Metrics.adminSessions(this.period).$promise.then(r => {
      this.metrics.sessions = r;
      this.loaded.sessions = true;
    });
  }

  adminEnergy = () => {
    this.loaded.energy = false;
    this.Metrics.adminEnergy(this.period).$promise.then(r => {
      this.metrics.energy = r;
      this.loaded.energy = true;
    });
  }

  adminRevenue = () => {
    this.loaded.revenue = false;
    this.Metrics.adminRevenue(this.period).$promise.then(r => {
      this.metrics.revenue = r;
      this.loaded.revenue = true;
    });
  }

  adminUsers = () => {
    this.loaded.users = false;
    this.Metrics.adminUsers(this.period).$promise.then(r => {
      this.metrics.users = r;
      this.loaded.users = true;
    });
  }

  getTypeChart = () => {
    this.loaded.typeChart = false;
    this.Metrics.adminTypeChart().$promise.then(r => {
      this.createTypeChart(r);
    });
  }

  createTypeChart = (data) => {
    let labels = ["Privado", "Condomínio", "Público"];
    let values = [
      (data.private / data.total * 100).toFixed(1),
      (data.condo / data.total * 100).toFixed(1),
      (data.public / data.total * 100).toFixed(1),
    ];
    this.typeChart = {
      labels: labels,
      data: values,
      options: {
        tooltips: {
          callbacks: {
            label: (data) => {
              return `${labels[data.index]}: ${values[data.index]}%`
            }
          }
        },
        responsive: false,
        legend: {
          display: true,
          position: 'left'
        }
      }
    }
    this.loaded.typeChart = true;
  }

  getStatusChart = () => {
    this.loaded.typeChart = false;
    this.Metrics.adminStatusChart().$promise.then(r => {
      this.createStatusChart(r);
    });
  }

  createStatusChart = (data) => {
    let labels = ["Disponível", "Ocupado", "A carregar", "Erro", "Offline"];
    let values = [
      (data.available / data.total * 100).toFixed(1),
      (data.occupied / data.total * 100).toFixed(1),
      (data.charging / data.total * 100).toFixed(1),
      (data.error / data.total * 100).toFixed(1),
      (data.offline / data.total * 100).toFixed(1),
    ];
    this.statusChart = {
      labels: labels,
      data: values,
      options: {
        tooltips: {
          callbacks: {
            label: (data) => {
              return `${labels[data.index]}: ${values[data.index]}%`
            }
          }
        },
        responsive: false,
        legend: {
          display: true,
          position: 'left'
        }
      }
    }
    this.loaded.typeChart = true;
  }

  getVendorChart = () => {
    this.loaded.typeChart = false;
    this.Metrics.adminVendorChart().$promise.then(r => {
      this.createVendorChart(r);
    });
  }

  createVendorChart = (data) => {
    let labels = data.map(r => r.name);
    let total = data.reduce((a, b) => a + b.total, 0);
    let values = data.map(r => (r.total / total * 100).toFixed(1));
    this.vendorChart = {
      labels: labels,
      data: values,
      options: {
        tooltips: {
          callbacks: {
            label: (data) => {
              return `${labels[data.index]}: ${values[data.index]}%`
            }
          }
        },
        responsive: false,
        legend: {
          display: true,
          position: 'left'
        }
      }
    }
    this.loaded.typeChart = true;
  }
}

DashboardAdminController.$inject = ['Metric', '$interval'];
