export default class EVSEMapController {
  constructor($state, EvseService, UIService) {
    this.Evse = EvseService;
    this.UI = UIService;

    this.mapOptions = {
      zoom: 6,
      center: [-7.80705, 39.74362],
    };

    this.markers = [];

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Evse.mapCharger().then((r) => {
      this.data = r;
      r.forEach((evse) => {
        if (evse.coordinates) {
          this.markers.push({
            title: evse.alias,
            coordinates: [evse.coordinates.lng, evse.coordinates.lat],
          });
        }
      });
      this.loaded = true;
    });
  };

  navigateTo = (row) => {
    if (row.coordinates) {
      this.mapOptions.animateCamera([row.coordinates.lng, row.coordinates.lat]);
    }
  };

  filter = () => {
    if (this.term.length > 0) {
      this.evses = [];
      this.data.forEach((r) => {
        this.evses.push(...r.evses);
      });
      let point = this.evses.find((r) =>
        r.alias.toLowerCase().includes(this.term.toLowerCase())
      );
      if (point) {
        this.navigateTo(point);
      }
    }
  };
}

EVSEMapController.$inject = ["$state", "EvseService", "UIService"];
