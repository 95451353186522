import React, { useEffect, useState } from "react";
import { Button, Container, Grid, MenuItem, Paper, Select, Switch, TextField, Typography, Box, Tab, Link, Stack, CircularProgress, } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
const EditContract = ({ $state, AdministrationService }) => {
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("1");
    const [isRescindChecked, setIsRescindChecked] = useState(false);
    const [isGroup6Visible, setIsGroup6Visible] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [entitiesList, setEntitiesList] = useState([]);
    const [entity, setEntity] = useState("");
    const [dataRescisao, setDataRescisao] = useState(moment());
    const [user, setUser] = useState({
        id: "",
        siteId: "",
        email: "",
        name: "",
    });
    const [contract, setContract] = useState({
        userId: $state.params.id,
        entityId: "",
        dataInicio: moment(),
        dataFim: moment(),
        rescisao: false,
        valorMensal: 0,
        valorCharge: 0,
        valorMin: 0,
        diaFaturacao: 1,
        dataRescisao: moment(),
        valorKwh: 0,
        valorOcupacao: 0,
        observacoes: "",
    });
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const resource = await AdministrationService.getContractById($state.params.contractId);
                setContract(resource);
                setIsRescindChecked(resource.rescisao);
                setIsGroup6Visible(resource.rescisao);
                const entities = await AdministrationService.getEntities();
                //simplifiedEntities = {id: 1, name: "A", id: 2, name: "B", ...}
                const simplifiedEntities = entities.map((e) => ({
                    id: e.id,
                    name: e.name,
                }));
                setEntitiesList(simplifiedEntities);
                if (simplifiedEntities.length > 0 && !entity) {
                    simplifiedEntities.map((e) => {
                        if (e.id === resource.entityId) {
                            setEntity(e.id);
                        }
                    });
                }
                const userInfo = await AdministrationService.getUser($state.params.id);
                setUser(userInfo[0]);
                setLoading(false);
            }
            catch (error) {
                if (contract?.id === "") {
                    console.error("Erro ao obter dados do contrato:", error.message);
                    setSnackbarMessage("Erro ao obter dados do contrato");
                    setOpenSnackbar(true);
                }
                if (entitiesList.length === 0) {
                    console.error("Erro ao obter entidades:", error.message);
                    setSnackbarMessage("Erro ao obter entidades");
                    setOpenSnackbar(true);
                }
                if (user === undefined) {
                    console.error("Erro ao obter user:", error.message);
                    setSnackbarMessage("Erro ao obter utilizador");
                    setOpenSnackbar(true);
                }
            }
        };
        if (loading) {
            fetchProjects();
        }
    }, [loading]);
    const validationSchema = yup.object({
        entityId: yup.string(),
        dataInicio: yup.date().required(),
        dataFim: yup
            .date()
            .min(yup.ref("dataInicio"), "A data de fim tem de ser superior à data de início.")
            .required(),
        valorMensal: yup
            .number()
            .test("Is positive?", "Valor não pode ser negativo", (value) => value >= 0),
        valorCharge: yup
            .number()
            .test("Is positive?", "Valor não pode ser negativo", (value) => value >= 0),
        valorMin: yup
            .number()
            .test("Is positive?", "Valor não pode ser negativo", (value) => value >= 0),
        diaFaturacao: yup.date(),
        dataRescisao: yup.date(),
        valorKwh: yup
            .number()
            .test("Is positive?", "Valor não pode ser negativo", (value) => value >= 0),
        valorOcupacao: yup
            .number()
            .test("Is positive?", "Valor não pode ser negativo", (value) => value >= 0),
    });
    const formik = useFormik({
        initialValues: {
            id: contract.id,
            userId: $state.params.id,
            entityId: contract.entityId,
            dataInicio: moment(contract.dataInicio),
            dataFim: moment(contract.dataFim),
            rescisao: contract.rescisao,
            valorMensal: contract.valorMensal,
            valorCharge: contract.valorCharge,
            valorMin: contract.valorMin,
            diaFaturacao: contract.diaFaturacao,
            valorKwh: contract.valorKwh,
            valorOcupacao: contract.valorOcupacao,
            observacoes: contract.observacoes,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            submit(values);
        },
    });
    const submit = async (values) => {
        if (isRescindChecked) {
            values.rescisao = true;
            values.dataRescisao = dataRescisao;
        }
        else {
            values.rescisao = false;
        }
        //values.dataFim.add(1, 'd');  /// add day removed in datePicker 
        try {
            await AdministrationService.updateContract(values);
            setSnackbarMessage("Contrato editado");
            setOpenSnackbar(true);
            setTimeout(() => $state.go("app.administration.users.edit", {
                id: $state.params.id,
            }), 1800);
        }
        catch (error) {
            console.error("Erro ao editar contrato:", error.message);
            setSnackbarMessage("Erro ao editar contrato");
            setOpenSnackbar(true);
        }
    };
    const handleEntidadeChange = (event) => {
        const selectedId = event.target.value;
        setEntity(selectedId);
        formik.setFieldValue("entityId", selectedId);
    };
    const handleRescindChange = (e) => {
        setIsRescindChecked(!isRescindChecked);
        setIsGroup6Visible(e.target.checked);
    };
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const goTo = () => {
        $state.go("app.administration.users.edit", { id: $state.params.id });
    };
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        formik.setFieldValue(id, value);
    };
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
      {loading ? (<Box sx={{
                marginTop: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                height: "100vh",
            }}>
          <CircularProgress sx={{ color: "#0f9d58" }} size={60}/>
        </Box>) : (<Container maxWidth={false}>
          <h1>{user.name}</h1>
          <h3>
            <Stack direction="row" spacing={1}>
              <Typography>
                <Link onClick={goTo} underline="none" color="inherit" className="black-link">
                  Editar utilizador{" "}
                </Link>
              </Typography>
              <Typography sx={{ color: "#777777" }}>/</Typography>
              <Typography sx={{ color: "#777777" }}>Editar contrato</Typography>
            </Stack>
          </h3>
          <form onSubmit={formik.handleSubmit}>
            <TabContext value={value}>
              <Paper style={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={(_e, newValue) => setValue(newValue)} aria-label="lab API tabs example">
                    <Tab label="Detalhes" value="1"/>
                    <Tab label="Financeiro" value="2"/>
                    <Tab label="Custos Mensais" value="3"/>
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <Grid container spacing={2}>
                    {/* Group 2 */}
                    <Grid item xs={2}>
                      <Typography>Entidade</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Select id="entidade" value={entity} onChange={handleEntidadeChange} sx={{ height: "30px", width: "200px" }} disabled={isRescindChecked}>
                        <MenuItem value="6" disabled>
                          Escolha uma Entidade
                        </MenuItem>
                        {entitiesList.map((entity) => (<MenuItem key={entity.id} value={entity.id}>
                            {entity.name}
                          </MenuItem>))}
                      </Select>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Data de Inicio </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("dataInicio", moment(value))} value={formik.values.dataInicio} slotProps={{
                textField: {
                    variant: "outlined",
                    id: "dataInicio",
                    InputProps: {
                        style: {
                            height: "30px",
                            width: "200px",
                        },
                    },
                    disabled: isRescindChecked,
                    //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                    //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
                },
            }}/>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Data de Fim </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <DatePicker format="DD/MM/YYYY" onChange={(value) => formik.setFieldValue("dataFim", moment(value))} value={moment.utc(formik.values.dataFim)} // remove one day because it shows 
         slotProps={{
                textField: {
                    variant: "outlined",
                    id: "dataFim",
                    InputProps: {
                        style: {
                            height: "30px",
                            width: "200px",
                        },
                    },
                    disabled: isRescindChecked,
                    //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                    //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
                },
            }}/>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField id="observacoes" label="Observações" multiline rows={3} sx={{ width: 400 }} value={formik.values.observacoes} onChange={handleTextInput} disabled={isRescindChecked}/>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Rescindir Contrato? </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Switch checked={isRescindChecked} value={formik.values.rescisao} onChange={handleRescindChange}/>
                    </Grid>

                    {/* Group 6 (DatePicker) */}
                    {isGroup6Visible && (<Grid item xs={12} sx={{ pb: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Typography>Data de Rescição </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <DatePicker format="DD/MM/YYYY" onChange={(value) => setDataRescisao(moment(value))} defaultValue={moment()} slotProps={{
                    textField: {
                        variant: "outlined",
                        id: "dataRescisao",
                        InputProps: {
                            style: {
                                height: "30px",
                                width: "200px",
                            },
                        },
                        //error: formik.touched.dataRescisao && Boolean(formik.errors.dataRescisao),
                        //helperText: formik.touched.dataRescisao && formik.errors.dataRescisao
                    },
                }}/>
                          </Grid>
                        </Grid>
                      </Grid>)}

                    {/* Avançar Button */}
                    <Grid item xs={12}>
                      <Grid item>
                        <Typography variant="caption">
                          <b>Nota:</b> Ao rescindir contrato impossibilta o
                          utilizador de carregar na instalação. Será depois
                          obrigatória a criação de um novo contrato.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="2">
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography>Valor €/Carregamento </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField type="number" id="valorCharge" name="valorCharge" variant="outlined" value={formik.values.valorCharge} onChange={formik.handleChange} error={Boolean(formik.errors.valorCharge)} disabled={isRescindChecked} InputProps={{
                style: {
                    height: "30px",
                    width: "200px",
                },
            }}/>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Valor €/minuto </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField type="number" id="valorMin" name="valorMin" variant="outlined" value={formik.values.valorMin} onChange={formik.handleChange} error={Boolean(formik.errors.valorMin)} disabled={isRescindChecked} InputProps={{
                style: {
                    height: "30px",
                    width: "200px",
                },
            }}/>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Valor €/kWh</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField type="number" id="valorKwh" name="valorKwh" variant="outlined" value={formik.values.valorKwh} onChange={formik.handleChange} error={Boolean(formik.errors.valorKwh)} disabled={isRescindChecked} InputProps={{
                style: {
                    height: "30px",
                    width: "200px",
                },
            }}/>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>Valor €/ocupação</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField type="number" id="valorOcupacao" name="valorOcupacao" variant="outlined" value={formik.values.valorOcupacao} onChange={formik.handleChange} error={Boolean(formik.errors.valorOcupacao)} disabled={isRescindChecked} InputProps={{
                style: {
                    height: "30px",
                    width: "200px",
                },
            }}/>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ pb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          <Typography>Valor €/Mensal</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={10}>
                          <TextField type="number" id="valorMensal" name="valorMensal" variant="outlined" value={formik.values.valorMensal} onChange={formik.handleChange} error={Boolean(formik.errors.valorMensal)} disabled={isRescindChecked} InputProps={{
                style: {
                    height: "30px",
                    width: "200px",
                },
            }}/>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ pb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          <Typography>Dia de Faturação</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={10}>
                          <Select id="diaFaturacao" name="diaFaturacao" value={formik.values.diaFaturacao} disabled={isRescindChecked} onChange={(event) => formik.setFieldValue("diaFaturacao", event.target.value)} style={{ height: "30px" }}>
                            {Array.from({ length: 30 }, (_, index) => (<MenuItem key={index + 1} value={index + 1}>
                                {index + 1}
                              </MenuItem>))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </TabContext>

            <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
              <Button type="submit" variant="contained">
                Submeter
              </Button>
            </Box>

            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
              <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} sx={{ backgroundColor: "black", color: "white" }} icon={false}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </form>
        </Container>)}
    </LocalizationProvider>);
};
export default EditContract;
