import React, { useState } from "react";
import { Box, Button, Card, Container, Grid, TextField, Typography, } from "@mui/material";
const testData = {
    margemHGP: '123',
    margemEntidade: '1234',
    valorCar: '1235',
    valorMin: '12356',
    valorKw: '1234567',
    valorOcup: '12345678',
};
// Validation function to allow only numbers
const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    // Check if input is a valid number
    if (/^\d*\.?\d*$/.test(inputValue)) {
        // Update state only if input is a valid number
        setter(inputValue);
    }
};
function TarifarioVenda() {
    const [editable, setEditable] = useState(false);
    const [margemHGP, setMargemHGP] = useState(testData.margemHGP);
    const [margemEntidade, setMargemEntidade] = useState(testData.margemEntidade);
    const [valorCar, setValorCar] = useState(testData.valorCar);
    const [valorMin, setValorMin] = useState(testData.valorMin);
    const [valorKw, setValorKw] = useState(testData.valorKw);
    const [valorOcup, setValorOcup] = useState(testData.valorOcup);
    const handleEdit = () => {
        setEditable(!editable);
    };
    return (<Container maxWidth={false}>
      <h1>Tarifário de Venda</h1>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {/* Group 1 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Margem HGP </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={margemHGP} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setMargemHGP)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 2 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Margem Entidade </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={margemEntidade} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setMargemEntidade)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 3 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/carga </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorCar} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setValorCar)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 4 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/min </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorMin} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setValorMin)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 5 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/kWh </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorKw} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setValorKw)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Group 6 */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Typography>Valor €/ocupação </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={10}>
                <TextField value={valorOcup} variant="outlined" InputProps={{
            style: {
                height: "30px",
            },
            readOnly: !editable,
        }} onChange={(e) => handleInputChange(e, setValorOcup)}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Edit Button */}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="caption">
                  <b>Nota:</b> Taxa de ocupação é o valor a debitar ao
                  utilizador quando o carregamento terminou mas continua a
                  ocupar o espaço do carregador, impossibilitando outros de
                  carregar
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
        <Button variant="contained" onClick={handleEdit}>
          {editable ? "Salvar" : "Editar"}
        </Button>
      </Box>
    </Container>);
}
export default TarifarioVenda;
