import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import { Container, Typography, } from "@mui/material";
import axios from "axios";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiEye, mdiTrashCan, } from "@mdi/js";
function locations({ $state }) {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5, //customize the default page size
    });
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalError, setModalError] = useState({
        show: false,
        title: "",
        body: "",
    });
    async function GetUsers() {
        let response;
        try {
            response = await axios.get("https://dev-hgp-ocpi.streamline.pt/hgp/locations/direct/", {
                headers: {
                    Authorization: "Token 2d83dd7c-ad5e-475f-ad7f-c4b51ce06d57",
                },
            });
        }
        catch (error) {
            setIsLoading(false);
            setModalError({
                show: true,
                title: error.response.data.error,
                body: error.response.data.message,
            });
            throw error;
        }
        return response.data;
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const data = await GetUsers();
            setData(data);
            setIsLoading(false);
        };
        fetchData();
    }, []);
    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "ID MOBIE",
            size: 50,
        },
        {
            accessorKey: "operator.name",
            header: "Designação OPC",
            size: 50,
        },
        {
            accessorKey: "address",
            header: "Morada",
            size: 50,
        },
        {
            accessorKey: "postal_code",
            header: "Código Postal",
            size: 50,
        },
        {
            accessorKey: "parking_type",
            header: "Tipo",
            size: 50,
        },
        {
            accessorKey: "mobie_voltage_level",
            header: "Tipo de Instalação Elétrica",
            size: 50,
        },
    ], []);
    const goto = (country_code, party_id, id) => {
        $state.go('app.reports.locations-view', { country_code, party_id, id });
    };
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Container maxWidth={false}>
      <h1>Informação de carregamentos</h1>
      <h3>Caminho</h3>
      <MaterialReactTable columns={columns} data={data} state={{
            density: "compact",
            isLoading: isLoading,
            showAlertBanner: modalError.show,
        }} localization={localization} muiCircularProgressProps={{
            color: "secondary",
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={modalError.show ? {
            color: "error",
            children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {modalError.title}:
                </Typography>
                <Typography>
                  {modalError.body}
                </Typography>
              </div>)
        } : undefined} enableRowActions positionActionsColumn={'last'} onPaginationChange={setPagination} renderRowActionMenuItems={({ table, row }) => [
            <MRT_ActionMenuItem icon={<MdiIcon path={mdiEye} size={0.7}/>} key="edit" label="Ver" onClick={() => {
                    const selectedRow = row.original;
                    goto(selectedRow.country_code, selectedRow.party_id, selectedRow.id);
                }} table={table}/>,
            <MRT_ActionMenuItem icon={<MdiIcon path={mdiTrashCan} size={0.7}/>} key="delete" label="Apagar" table={table}/>,
        ]}/>
    </Container>);
}
export default locations;
