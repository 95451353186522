export default class DashboardController {
  constructor($state, AuthorizationService) {
    let a = AuthorizationService.getAllowed();
    if (AuthorizationService.canPerform(["dashAdmin"])) {
      $state.go('app.dashboard.admin');
    } else if (AuthorizationService.canPerform(["dashManager"])) {
      $state.go('app.dashboard.manager');
    }
  }
}

DashboardController.$inject = ['$state', 'AuthorizationService'];
