import moment from 'moment/moment';

export function routes($stateProvider) {
  $stateProvider
    .state('app.transaction', {
      url: '/transactions',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.transaction.list', {
      url: '?{page:int}&{order:string}&{sort:string}&{from:string}&{to:string}',
      template: require('./list/view.html'),
      controller: 'TransactionListController',
      controllerAs: 'vm',
      params: {
        from: moment.utc().startOf('day').subtract(7,'day').format("DD-MM-YYYY"),
        to: moment.utc().endOf('day').format("DD-MM-YYYY")
      }
    }).state('app.transaction.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'TransactionDetailsController',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
