import { routes } from './routes';
import TarifarioLista from './tarifario/TarifarioLista.tsx';
import TarifarioVenda from './tarifario/TarifarioVenda.tsx';
import { react2angular } from 'react2angular';

export default angular.module('app.tarifario', [])
    .config(routes)
    .component("tarifarioLista", react2angular(TarifarioLista, [], []))
    .component("tarifarioVenda", react2angular(TarifarioVenda, [], []))
    .name;
