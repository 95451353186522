import { routes } from "./routes";
import { react2angular } from "react2angular";
import OCPILocationList from "./locations/list.tsx";
import Charger from "./locations/charger.tsx";
import Sessions from "./locations/sessions.tsx";
import OCPICDRList from "./cdrs/list.tsx";

export default angular
  .module("app.ocpi", [])
  .config(routes)
  .component("listLocations", react2angular(OCPILocationList, [], []))
  .component("chargerList", react2angular(Charger, [], []))
  .component("sessionList", react2angular(Sessions, [], []))
  .component("listCdrs", react2angular(OCPICDRList, [], [])).name;
