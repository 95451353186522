import {
  routes
} from './routes';
import ReportService from './service';
import ReportEntityController from './entity/controller';
import ReportSiteController from './site/controller';
import ReportUserController from './user/controller';

export default angular.module('app.Reports', [])
  .config(routes)
  .service('ReportService', ReportService)
  .controller('ReportEntityController', ReportEntityController)
  .controller('ReportSiteController', ReportSiteController)
  .controller('ReportUserController', ReportUserController)
  .name;
